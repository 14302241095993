import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import BUDGETREE from "../../Assets/Projects/budgetree.png";
import AAGYO from "../../Assets/Projects/aagyo.png";
import IPACADEMY from "../../Assets/Projects/ip-academy.png";
import LESSPAY from "../../Assets/Projects/lesspay.png";
import SETUFI from "../../Assets/Projects/setufi.png";
import SIRIPAY from "../../Assets/Projects/siripay.png";
import TRAWEGO from "../../Assets/Projects/trawego.png";
import ULTRANET from "../../Assets/Projects/ultranet.png";
import DOCPULSE from "../../Assets/Projects/docpulse.png";

function Projects() {
  return (
    <Container fluid className='project-section'>
      <Particle />
      <Container>
        <h1 className='project-heading'>
          My Recent <strong className='purple'>Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={BUDGETREE}
              isBlog={false}
              title='Budgetree'
              description="I worked on **Budgetree**'s backend system, integrating **third-party APIs** and implementing **OAuth2.0** for secure user authentication. This project showcases my ability to work with external services and ensure secure, seamless data exchange between systems. I also optimized the API integration to improve performance and reduce latency. Additionally, I ensured compliance with security standards to safeguard user information and data."
              ghLink=''
              demoLink='https://www.budgetree.in/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={AAGYO}
              isBlog={false}
              title='Aagyo'
              description='I created **Aagyo**, a food delivery platform operating in the UK, known for its seamless user experience and efficient logistics. Aagyo reflects my ability to develop scalable, impactful solutions and my passion for innovation in real-world applications. I integrated **SNS** for messaging and notifications, and **S3** for optimized media storage, ensuring smooth and reliable service.'
              ghLink=''
              demoLink='https://www.aagyo.in/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={IPACADEMY}
              isBlog={false}
              title='IP Academy'
              description='"I developed **IP Academy**, an academy portal where students can attend classes, access documents, watch videos, and enhance their learning experience. The platform also allows admins to manage students efficiently and integrates a seamless payment system, showcasing my expertise in creating functional and user-focused solutions.".'
              ghLink=''
              demoLink='https://www.portal.globalipacademy.in/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={LESSPAY}
              isBlog={false}
              title='Lesspay'
              description='I worked on **Lesspay**, focusing on its **microservice architecture** using **Node.js** and integrating **Kafka** for real-time messaging. This project showcases my ability to design scalable, distributed systems and implement efficient communication between services. I also optimized service interactions, ensuring high availability and fault tolerance. The use of Kafka improved data flow and reduced latency across microservices.'
              ghLink=''
              demoLink='https://www.lesspay.ai/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={SETUFI}
              isBlog={false}
              title='SetuFi'
              description='I contributed to **SetuFi**, leveraging **Node.js** to implement **cron jobs** and automation, ensuring efficient task scheduling and streamlined operations. This project highlights my skills in backend development and process optimization. The automation improved system performance, reducing manual intervention. It also demonstrates my ability to develop scalable solutions for complex business requirements.'
              ghLink=''
              demoLink='https://www.setufi.com/'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={SIRIPAY}
              isBlog={false}
              title='Siripay'
              description="I worked on **Siripay**, focusing on its backend development and integrating a secure **payment method**. This project highlights my expertise in building reliable payment systems and ensuring smooth transactions. I also ensured the platform's scalability, allowing for seamless handling of increasing transaction volumes. Additionally, I implemented robust security measures to protect sensitive user data."
              ghLink=''
              demoLink='https://www.siripay.com/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={TRAWEGO}
              isBlog={false}
              title='Trawego'
              description='I developed **Trawego**, a Dubai-based ticket booking platform with seamless **payment gateway integration**. The project combines modern design with efficient functionality, ensuring a smooth booking experience. It demonstrates my expertise in creating user-friendly, scalable, and secure web solutions.'
              ghLink=''
              demoLink='https://www.trawego.com/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={ULTRANET}
              isBlog={false}
              title='Ultranet'
              description='I developed **Ultranet**, a dynamic application built with **React**, styled using **Tailwind CSS**, and enhanced with animations powered by **Framer Motion** for an engaging user experience. The platform delivers seamless performance with visually appealing transitions. It reflects my ability to create modern, interactive, and user-centric web solutions.'
              ghLink=''
              demoLink='https://www.ultranet.com/'
            />
          </Col>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={DOCPULSE}
              isBlog={false}
              title='DocPulse'
              description='I built the backend for **DocPulse** using **Node.js**, implementing payment systems, scheduling tasks with **cron jobs**, and deploying the solution on **EC2** with **S3 CDN integration** for optimized performance and scalability.'
              ghLink=''
              demoLink='https://www.docpulse.com/'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
