import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/rajat.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col md={8} className='home-about-description'>
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className='purple'> INTRODUCE </span> MYSELF
            </h1>
            <p className='home-about-body'>
              I fell in love with programming and I have at least learnt
              something, I think… 🤷‍♂️
              <br />
              <br />I am fluent in classics like
              <i>
                <b className='purple'> Javascript, Python and Typescript. </b>
              </i>
              which form the foundation of my programming journey.
              <br />
              <br />
              My primary areas of interest lie in building{" "}
              <i>
                <b className='purple'>
                  new web technologies and innovative products
                </b>
              </i>
              . I’m deeply passionate about{" "}
              <i>
                <b className='purple'>
                  crafting efficient, scalable, and user-centric applications
                </b>
              </i>
              , with a particular focus on{" "}
              <i>
                <b className='purple'>backend development</b>
              </i>{" "}
              and the architecture that powers great digital experiences.
              <br />
              <br />
              Whenever possible, I channel my enthusiasm for development into
              creating impactful products using{" "}
              <i>
                <b className='purple'>Node.js </b>
              </i>{" "}
              and modern{" "}
              <i>
                <b className='purple'>
                  JavaScript libraries and frameworks, such as React.js,
                  Next.js, Nest.js, and Express.js
                </b>
              </i>
              . My love for clean, maintainable code aligns with my drive to
              learn and implement best practices in both{" "}
              <i>
                <b className='purple'>frontend and backend </b>
              </i>
              systems.
              <br />
              <br />
              Additionally, I enjoy exploring advanced topics in{" "}
              <i>
                <b className='purple'>API design, real-time data handling</b>
              </i>
              , and{" "}
              <i>
                <b className='purple'>microservices architecture</b>
              </i>
              , leveraging tools like{" "}
              <i>
                <b className='purple'>GraphQL, WebSockets, and Docker </b>
              </i>{" "}
              to enhance development processes. Collaboration,{" "}
              <i>
                <b className='purple'>problem-solving</b>
              </i>
              , and staying at the forefront of technology fuel my drive to
              constantly improve and innovate.
            </p>
          </Col>
          <Col md={3} className='myAvtar'>
            <Tilt
              className=''
              style={{
                height: "300px",
                width: "300px",
                borderRadius: "9999px",
                overflow: "hidden",
              }}
            >
              <img src={myImg} className='img-fluid' alt='avatar' />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className='purple'>connect </span>with me
            </p>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a
                  href='https://github.com/rajatv7865v'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/rajat-verma-5b1b74239/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.instagram.com/mr__rajjo/'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour home-social-icons'
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
