import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: "justify", fontSize: "17px" }}>
            Hi Everyone, I am <span className='purple'>Rajat Verma </span>
            from <span className='purple'> Uttar Pradesh, India.</span> I am
            currently employed as a{" "}
            <span className='purple'>Software developer</span>.
            <br />
            <br />I hold a
            <span className='purple'>
              {" "}
              Master's degree in Computer Applications (MCA)
            </span>{" "}
            from Chandigarh University and a{" "}
            <span className='purple'>
              Bachelor's degree in Computer Applications (BCA)
            </span>{" "}
            from C.S.J.M University, Kanpur.
            <br />
            <br />
            With a strong foundation in programming and{" "}
            <span className='purple'>problem-solving</span>, I specialize in
            developing innovative software solutions that drive business
            success. My expertise includes:
          </p>
          <ul style={{ fontSize: "16px", textAlign: "start", padding: "5px" }}>
            <li>
              {" "}
              <b className='purple'>Frontend Development : </b>
              Building modern, responsive, and user-friendly interfaces.
            </li>
            <li style={{ paddingTop: "2px" }}>
              {" "}
              <b className='purple'>Backend Development : </b>
              Designing robust APIs and scalable server-side applications.
            </li>
            <li style={{ paddingTop: "2px" }}>
              {" "}
              <b className='purple'>Database Management : </b>
              Expertise in managing and optimizing relational and non-relational
              databases.
            </li>
          </ul>
          <p style={{ textAlign: "justify", fontSize: "17px" }}>
            I am passionate about continuous learning and staying updated with
            emerging technologies. I thrive in collaborative environments and
            enjoy tackling <span className='purple'>challenging projects</span>{" "}
            that push my
            <span className='purple'> technical</span> and{" "}
            <span className='purple'> creative boundaries</span>.
          </p>
          <p style={{ textAlign: "start", fontSize: "17px" }}>
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className='about-activity'>
              <ImPointRight /> Playing Games
            </li>
            <li className='about-activity'>
              <ImPointRight /> Reading Books
            </li>
            <li className='about-activity'>
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
